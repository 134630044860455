import styles from './icon.module.scss';

import Apple from './svgs/apple.svg';
import ArrowDown from './svgs/arrow-down.svg';
import ArrowLeft from './svgs/arrow-left.svg';
import ArrowNorthEast from './svgs/arrow-north-east.svg';
import ArrowRight from './svgs/arrow-right.svg';
import Check from './svgs/check.svg';
import Checkmark from './svgs/checkmark.svg';
import ChevronDown from './svgs/chevron-down.svg';
import ChevronUp from './svgs/chevron-up.svg';
import Clock from './svgs/clock.svg';
import One from './svgs/counter01.svg';
import Two from './svgs/counter02.svg';
import Three from './svgs/counter03.svg';
import Four from './svgs/counter04.svg';
import Five from './svgs/counter05.svg';
import Six from './svgs/counter06.svg';
import Seven from './svgs/counter07.svg';
import Eight from './svgs/counter08.svg';
import Nine from './svgs/counter09.svg';
import Ten from './svgs/counter10.svg';
import Cross from './svgs/cross.svg';
import EmailAlt from './svgs/email-alt.svg';
import Email from './svgs/email.svg';
import Facebook from './svgs/facebook.svg';
import File from './svgs/file.svg';
import Github from './svgs/github.svg';
import Hamburger from './svgs/hamburger.svg';
import Heart from './svgs/heart.svg';
import Info from './svgs/info.svg';
import Instagram from './svgs/instagram.svg';
import Linkedin from './svgs/linkedin.svg';
import Logo from './svgs/logo.svg';
import LongArrow from './svgs/long-arrow.svg';
import MouseScroll from './svgs/mouse-scroll.svg';
import Phone from './svgs/phone.svg';
import Play from './svgs/play.svg';
import Plus from './svgs/plus.svg';
import Podimo from './svgs/podimo.svg';
import Rank from './svgs/rank.svg';
import Spotify from './svgs/spotify.svg';
import Tiktok from './svgs/tiktok.svg';
import Trash from './svgs/trash.svg';
import Triangle from './svgs/triangle.svg';
import Twitter from './svgs/twitter.svg';
import Upload from './svgs/upload.svg';
import WhatsApp from './svgs/whatsapp.svg';
import Zigzag from './svgs/zigzag.svg';

export const Icons = {
  Apple,
  ArrowDown,
  ArrowLeft,
  ArrowNorthEast,
  ArrowRight,
  Check,
  ChevronDown,
  ChevronUp,
  Clock,
  Cross,
  Eight,
  Email,
  EmailAlt,
  Facebook,
  File,
  Five,
  Four,
  Github,
  Hamburger,
  Heart,
  Info,
  Instagram,
  Linkedin,
  Logo,
  LongArrow,
  MouseScroll,
  Nine,
  One,
  Phone,
  Play,
  Plus,
  Podimo,
  Rank,
  Seven,
  Six,
  Spotify,
  Ten,
  Three,
  Tiktok,
  Trash,
  Triangle,
  Twitter,
  Two,
  Upload,
  WhatsApp,
  Zigzag,
  Checkmark,
};

export interface IconProps {
  name: keyof typeof Icons;
  className?: string;
}

export const Icon = ({ name, className }: IconProps) => {
  const Component = Icons[name];

  return <Component className={`${styles.icon} ${className ?? ''}`} />;
};
